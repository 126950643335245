import React from 'react';

import './Styles/Navbar.css';

import {
    Link,
} from 'react-router-dom';

export default function Navbar() {

    return (
        <div className='Navbar'>
            <Link to='/'>
                <button className='button__icon'>🏠</button>
            </Link>
            <Link to='/about'>
                <button className='button__icon'>❔</button>
            </Link>
        </div>

        
    )
}