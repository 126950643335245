import React from 'react';

import './Styles/Default.css';
import './Styles/About.css';

export default function About() {
    return (
        <div className='Body'>
            
        </div>
    )
}