import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import {
  Home,
  About,
  HTML5
} from './Pages/index';

import {
  Navbar,
} from './Components/index';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/@bookmarks/' element={<HTML5 />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
