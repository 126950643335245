import React from 'react';

import './Styles/games.css';

import {
    Link,
} from 'react-router-dom';

export default function Home() {
    return (
        <div className='Games'>
            <h1>@bookmarks/</h1>
            <div className="games__library">
                <p className='subheader'>Social Studies</p>
                <div className="library__column">
                    <a href="https://dreamscape.shoelacegaming.com/" target='_blank'>
                    <div className="library__result">
                        <img width='140' height='140' src="https://upload.wikimedia.org/wikipedia/commons/5/59/Google_Classroom_Logo.png" alt="" />
                        <h2>Bellringer</h2>
                    </div>
                    </a>
                    <a href="https://sso.prodigygame.com/game/login" target='_blank'>
                    <div className="library__result">
                        <img width='140' height='140' src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Google_Sites_2020_Logo.svg/800px-Google_Sites_2020_Logo.svg.png" alt="" />
                        <h2>Class Page</h2>
                    </div>
                    </a>
                </div>
                <p className='subheader'>Computer Literacy</p>
                <div className="library__column">
                    <a href="https://www.typingclub.com/" target='_blank'>
                    <div className="library__result">
                        <img width='140' height='140' src="https://pbs.twimg.com/profile_images/804773067025215488/ApC4doGC_400x400.jpg" alt="" />
                        <h2>TypingClub</h2>
                    </div>
                    </a>
                    <a href="https://www.typerush.com/" target='_blank'>
                    <div className="library__result">
                        <img width='140' height='140' src="https://www.typerush.com/assets/images/common/android-chrome-192x192.png" alt="" />
                        <h2>Type Race</h2>
                    </div>
                    </a>
                </div>
            </div>
        </div>
    )
}