import React from 'react';

import './Styles/Home.css';

import {
    Link,
} from'react-router-dom';

export default function Home() {
    return (
        <div className='Home'>
            <Link to='/c'>
            <img src="https://d585tldpucybw.cloudfront.net/sfimages/default-source/svg/header-react-bg.svg" width='295' height='262' />
            </Link>
            <h1>@unk3mpt school</h1>
            <p>psssst.. to join the school chat click the image above this text.</p>
            <p className="subheader">
                Loading fact..
            </p>
            <p></p>
            <div className="Body__column">
                <Link to='/@bookmarks/'><button>Bookmarks</button></Link>
                <button style={{border: '4px solid #625fff', color: '#625fff'}}>Support The Project!</button>
            </div>
        </div>
    )
}